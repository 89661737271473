module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kitwind","short_name":"Kitwind","start_url":"/","background_color":"#604BFF","theme_color":"#604BFF","display":"minimal-ui","icon":"assets/images/logo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1024,"backgroundColor":"transparent","wrapperStyle":"white-space: normal; box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.15); margin-top: 3.5rem; margin-bottom: 3.5rem; border-radius: 0.25rem; overflow:hidden;"}},"gatsby-remark-copy-linked-files","gatsby-remark-smartypants"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
